import React, { useRef, useEffect } from 'react';
import { utilAnimate } from '../../utils/utilAnimate';
import styles from './stylesNextSteps.module.scss';


export default function ButtonNextStepsItem ( { item, currItem, selectItem } ) {
	
	// const displayDescrption = item.title === currItem ? 'block' : 'none';

	const containerRef = useRef(null);
	useEffect(() => {
		item.title === currItem ? utilAnimate.fadeIn(containerRef.current) : utilAnimate.fadeOut(containerRef.current);
	});

	return (
    	<button 
			style={ { left: item.btnXPos, top: item.btnYPos } } 
			className={ item.title === currItem ? styles.btn__active : styles.btn } 
			onClick={ () => selectItem(item.title) } 
    	>
        	<img src={ item.iconInactive } width='138' height='163' alt={ item.title + ' icon' } />
			<p className={ styles.btnDescription } ref={ containerRef } >{ item.description }</p>
        	<h2>{ item.title }</h2>
    	</button>
  );
}
