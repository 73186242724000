
import ActivateInactive from '../../images/next-steps/icon--activate-blue.png';
import ActivateActive from '../../images/next-steps/icon--activate-white.png';
import AccelerateInactive from '../../images/next-steps/icon--accelerate-blue.png';
import AccelerateActive from '../../images/next-steps/icon--accelerate-white.png';
import AmplifyInactive from '../../images/next-steps/icon--amplify-blue.png';
import AmplifyActive from '../../images/next-steps/icon--amplify-white.png';


export const data = [
    { 
        title: 'Activate',
        overlaySrc: 'bkg--next-steps-1.jpg',
        overlayNode: null,
        iconInactive: ActivateInactive,
        iconActive: ActivateActive,
        btnXPos: 0,
        btnYPos: 0,
        description: 'Activate the ecosystem by giving MAB the green light to plan and develop Greater Avalon.'
    },
    { 
        title: 'Accelerate',
        overlaySrc: 'bkg--next-steps-2.jpg',
        overlayNode: null,
        iconInactive: AccelerateInactive,
        iconActive: AccelerateActive,
        btnXPos: 0,
        btnYPos: 330,
        description: 'Accelerate development by harnessing MAB’s strong networks to secure quality anchor tenants with a history of long-term investment, innovation and job creation.'
    },
    { 
        title: 'Amplify',
        overlaySrc: 'bkg--next-steps-3.jpg',
        overlayNode: null,
        iconInactive: AmplifyInactive,
        iconActive: AmplifyActive,
        btnXPos: 0,
        btnYPos: 660,
        description: 'Amplify new local business investment and services that drive airport growth and create critical mass by leveraging anchor tenants and infrastructure investment.'
    }
];