import React, { useRef, useEffect } from 'react';
import Img from 'gatsby-image';
import { utilAnimate } from '../../utils/utilAnimate';
import styles from './stylesNextSteps.module.scss';


export default function ViewNextStepsOverlay ( { item, currItem } ) {
  
  	// const display = item.title === currItem ? 'block' : 'none'; //only if it's the currently active item

	const containerRef = useRef(null);
	useEffect(() => {
		item.title === currItem ? utilAnimate.fadeIn(containerRef.current) : utilAnimate.fadeOut(containerRef.current);
	});

  	return (
    	<div className={ styles.overlay } ref={ containerRef }>
        	<Img fixed={ item.overlayNode.childImageSharp.fixed } key={ item.overlayNode.id } alt={ item.title } />
    	</div>
  	);

}
