import React from 'react';
import SEO from '../components/seo/SEO';
import ViewNextSteps from '../components/nextSteps/ViewNextSteps';


export default function NextSteps () { 

  return (
    <>
      <SEO title='Next Steps' />
      <ViewNextSteps />
    </>
  );

}
