
import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { data } from './dataNextSteps';
import ViewNextStepsBkg from './ViewNextStepsBkg';
import ViewNextStepsOverlay from './ViewNextStepsOverlay';
import ButtonNextStepsItem from './ButtonNextStepsItem';
import styles from './stylesNextSteps.module.scss';


export default function ViewNextSteps () {

    const [ currItem, setCurrItem ] = useState('');
    const onItemSected = (item) => {
        const newItem = item;
        setCurrItem(newItem);
    }

    const overlaysQuery = useStaticQuery(graphql`
	{
		allFile(filter: {relativeDirectory: {eq: "next-steps"}, base: {regex: "/bkg/"}}) {
			edges {
				node {
					id
					base
					childImageSharp {
						fixed(width: 1920, height: 1080, quality: 80) {
                            ...GatsbyImageSharpFixed_noBase64
                        }
					}
				}
			}
		}
	}
    `);
    
    const getImgNodes = () => { //use the retrieved file names to match the data up and assign the nodes for Gatsby IMG
        const imagesList = overlaysQuery.allFile.edges;
        data.forEach( item => {
            const matchedImgNode = imagesList.find( img => img.node.base === item.overlaySrc ); 
            item.overlayNode = matchedImgNode.node;
        });
    }
    getImgNodes();

    const getOverlays = () => {
        const overlays = data.map( item => 
            <ViewNextStepsOverlay key={ item.title } item={ item } currItem={ currItem } />
        );
        return overlays;
    }

    const getButtons = () => {
        const buttons = data.map( item => 
            <li key={ item.title }>
                <ButtonNextStepsItem item={ item } currItem={ currItem } selectItem={ onItemSected } />
            </li>
        );
        return buttons;
    }

    return (
        <>
           <ViewNextStepsBkg />
           <div className={ styles.frg }>
             { getOverlays() }
           </div>
           <nav className={ styles.menu }>
                <ul>
                    { getButtons() }
                </ul>
            </nav>
        </>
    );

}